/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// const addScript = url => {
//     const script = document.createElement("script")
//     script.src = url
//     script.async = true
//     document.body.appendChild(script)
// }

const addStylesheet = url => {
    const link = document.createElement("link")
    link.href = url
    link.rel = "stylesheet"
    document.head.appendChild(link)
}

export const onClientEntry = () => {
    window.onload = () => {
        addStylesheet("https://use.fontawesome.com/releases/v5.11.2/css/all.css")
    }
}
  